import {LitElement, html } from 'lit';
import {customElement,state} from 'lit/decorators.js';

@customElement('nav-bar')
export class NavBar extends LitElement {

  @state()
  navClicked = false
 
  constructor() {
    super();
  }

  override render() {
    const style = html 
    `
      <style>@import "https://cdnjs.cloudflare.com/ajax/libs/flowbite/2.3.0/flowbite.min.css"</style>
      <style>@import "styles.css</style>
    `;
    
    return html 
    `${style}
    <nav class="bg-white">
    <div class="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
        <a href="/" class="flex items-center text-green-vogue wrapper">
            <img class="h-8 rotate" src="logo.png">&nbsp;
            <span class="text-2xl font-semibold whitespace-nowrap dark:text-white" style="font-family: Days One, sans-serif;">V</span>
            <span class="text-2xl font-semibold whitespace-nowrap dark:text-white" style="font-family: Days One, sans-serif;">O</span>
            <span class="text-2xl font-semibold whitespace-nowrap dark:text-white" style="font-family: Days One, sans-serif;">R</span>
            <span class="text-2xl font-semibold whitespace-nowrap dark:text-white" style="font-family: Days One, sans-serif;">T</span>
            <span class="text-2xl font-semibold whitespace-nowrap dark:text-white" style="font-family: Days One, sans-serif;">E</span>
            <span class="text-2xl font-semibold whitespace-nowrap dark:text-white" style="font-family: Days One, sans-serif;">X</span>
        </a>
        <button type="button" class="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-green-vogue rounded-lg md:hidden bg-green-vogue-200 focus:outline-none focus:ring-2 focus:ring-blue-200 dark:text-blue-400 dark:hover:bg-blue-700 dark:focus:ring-blue-600" @click=${()=>this.navClicked = !this.navClicked}>
            <span class="sr-only">Open main menu</span>
            <svg class="w-5 h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h15M1 7h15M1 13h15"/>
            </svg>
        </button>
        <div class="${this.navClicked ? null : 'hidden'} w-full md:block md:w-auto" id="navbar-default" style="font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;">
        <ul class="font-medium flex flex-col p-4 md:p-0 mt-4 border rounded-lg ${this.navClicked ? 'bg-green-vogue-100' : null} md:flex-row md:space-x-8 rtl:space-x-reverse md:mt-0 md:border-0 md:bg-white dark:bg-blue-800 md:dark:bg-blue-700 dark:border-blue-700">
            <li>
            <a href="/products.html" class="block py-2 px-3 text-green-vogue rounded bg-green-vogue-200 md:hover:bg-transparent md:border-0 md:hover:text-gray-900 md:p-0 dark:text-white md:dark:hover:text-green-vogue-500 dark:hover:bg-blue-700 dark:hover:text-white md:dark:hover:bg-transparent">Products & Services</a>
            </li>
            <li>
            <a href="/contact.html" class="block py-2 px-3 text-green-vogue rounded bg-green-vogue-200 md:hover:bg-transparent md:border-0 md:hover:text-gray-900 md:p-0 dark:text-white md:dark:hover:text-green-vogue-500 dark:hover:bg-blue-700 dark:hover:text-white md:dark:hover:bg-transparent">Contact</a>
            </li>
        </ul>
        </div>
    </div>
    </nav>
    `;
  }
}
//customElements.define('contact-form', ContactForm);